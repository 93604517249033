@charset "UTF-8";
.icon {
  background-image: url(../images/icon.png);
  background-size: 452px auto;
  display: inline-block;
}

.Artboard {
  background-position: -74px -281px;
  width: 77px;
  height: 16px;
}

.bag-active {
  background-position: -32px -318px;
  width: 32px;
  height: 32px;
}

.bag {
  background-position: -192px -318px;
  width: 32px;
  height: 32px;
}

.clock {
  background-position: -304px -318px;
  width: 24px;
  height: 24px;
}

.cloth-active {
  background-position: -96px -318px;
  width: 32px;
  height: 32px;
}

.cloth {
  background-position: -64px -318px;
  width: 32px;
  height: 32px;
}

.leftarrow {
  background-position: -37px -281px;
  width: 37px;
  height: 37px;
}

.location {
  background-position: -256px -318px;
  width: 24px;
  height: 24px;
}

.logo_balenciaga {
  background-position: -226px -168px;
  width: 226px;
  height: 56px;
}

.logo_bottega-veneta {
  background-position: 0px -225px;
  width: 226px;
  height: 56px;
}

.logo_chanel {
  background-position: -226px -112px;
  width: 226px;
  height: 56px;
}

.logo_clarks {
  background-position: -226px -56px;
  width: 226px;
  height: 56px;
}

.logo_d_g {
  background-position: -226px 0px;
  width: 226px;
  height: 56px;
}

.logo_dior {
  background-position: -226px -225px;
  width: 226px;
  height: 56px;
}

.logo_gucci {
  background-position: 0px -57px;
  width: 226px;
  height: 56px;
}

.logo_hermes {
  background-position: 0px 0px;
  width: 226px;
  height: 57px;
}

.logo_prada {
  background-position: 0px -169px;
  width: 226px;
  height: 56px;
}

.logo_valentino {
  background-position: 0px -113px;
  width: 226px;
  height: 56px;
}

.navigation {
  background-position: -280px -318px;
  width: 24px;
  height: 24px;
}

.other-active {
  background-position: 0px -318px;
  width: 32px;
  height: 32px;
}

.other {
  background-position: -224px -318px;
  width: 32px;
  height: 32px;
}

.point {
  background-position: -151px -281px;
  width: 12px;
  height: 12px;
}

.rightarrow {
  background-position: 0px -281px;
  width: 37px;
  height: 37px;
}

.shoes-active {
  background-position: -160px -318px;
  width: 32px;
  height: 32px;
}

.shoes {
  background-position: -128px -318px;
  width: 32px;
  height: 32px;
}

.telphone {
  background-position: -328px -318px;
  width: 24px;
  height: 24px;
}

/* Space out content a bit */
/*============================================*/
body {
  margin: 0;
  padding: 0;
  background: #fff;
  font-size: 14px;
  font-family: ‘微软雅黑’,Arial,Helvetica,'STHeiti','Microsoft YaHei','sans-serif';
}

form, ul, li, p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

img {
  border: 0;
}

ul, li {
  list-style-type: none;
}

a {
  color: #00007F;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/*===========================================*/
body {
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0;
  min-width: 960px;
  margin: 0 auto;
  font-family: ‘微软雅黑’;
  overflow-x: hidden;
}

body div {
  width: auto;
}

@media only screen and (max-width: 960px) {
  body {
    overflow-x: auto;
    margin: 0;
  }
}

.topbar {
  background-color: #BFDDDD;
  height: 64px;
}

.banner,
.container_in,
.topbar,
footer,
footer img {
  min-width: 960px;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
}

.banner .slick-next,
.banner .slick-prev,
.container_in .slick-next,
.container_in .slick-prev,
.topbar .slick-next,
.topbar .slick-prev,
footer .slick-next,
footer .slick-prev,
footer img .slick-next,
footer img .slick-prev {
  z-index: 5555;
  border: none;
  outline: none;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 74px;
  height: 74px;
  -webkit-transform: translate(0, -50%) scale(0.5);
  -ms-transform: translate(0, -50%) scale(0.5);
  transform: translate(0, -50%) scale(0.5);
  cursor: pointer;
  color: transparent;
  background: transparent;
  background-repeat: no-repeat;
  background-image: url("../images/other/css_sprites.png");
}

.banner .slick-next,
.container_in .slick-next,
.topbar .slick-next,
footer .slick-next,
footer img .slick-next {
  right: 25px;
  background-position: -10px -104px;
}

.banner .slick-prev,
.container_in .slick-prev,
.topbar .slick-prev,
footer .slick-prev,
footer img .slick-prev {
  background-position: -10px -10px;
  left: 25px;
}

.banner .slick-dots,
.container_in .slick-dots,
.topbar .slick-dots,
footer .slick-dots,
footer img .slick-dots {
  position: absolute;
  bottom: 6px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}

.abouts,
.charges,
.contrast,
.positiondetail,
.service,
.symptom {
  margin: 0 auto;
  min-width: 896px;
  max-width: 1216px;
  width: 100%;
}

.abouts {
  min-width: 960px;
  max-width: 1230px;
  width: 100%;
}

.logoimg {
  float: left;
  margin: 14px 24px;
}

.navbar {
  float: right;
  width: 493px;
  height: 35px;
  padding-top: 20px;
}

.nav {
  display: flex;
}

.nav a {
  display: block;
  color: #000000;
  font-size: 17px;
  list-style: none;
  width: 40px;
  margin-right: 52px;
  text-decoration: none;
}

.nav a:hover {
  color: inherit;
  list-style: none;
}

.logoimg {
  width: 103px;
  height: auto;
}

.banner {
  height: auto;
}

.banner .bannertype_one,
.banner .bannertype_three,
.banner .bannertype_two {
  height: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.banner .bannertype_one img,
.banner .bannertype_three img,
.banner .bannertype_two img {
  width: 100%;
}

.symptom {
  height: auto;
}

.symptom button {
  outline: none;
}

.symptom .symptomtype_four,
.symptom .symptomtype_one,
.symptom .symptomtype_three,
.symptom .symptomtype_two {
  height: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.symptom .symptomtype_four img,
.symptom .symptomtype_one img,
.symptom .symptomtype_three img,
.symptom .symptomtype_two img {
  height: auto;
  width: 100%;
}

.contrast {
  height: 676px;
}

.contrast .contrasttype_four,
.contrast .contrasttype_one,
.contrast .contrasttype_three,
.contrast .contrasttype_two {
  position: relative;
  max-width: 1216px;
  min-width: 960px;
  width: 100%;
  height: 676px;
}

.charges {
  height: auto;
}

.charges button {
  outline: none;
}

.charges .chargestype_four,
.charges .chargestype_one,
.charges .chargestype_three,
.charges .chargestype_two {
  height: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.charges .chargestype_four img,
.charges .chargestype_one img,
.charges .chargestype_three img,
.charges .chargestype_two img {
  height: auto;
  width: 100%;
}

.service {
  height: 1030px;
  position: relative;
}

.servicefivemark,
.servicefourmark,
.serviceonemark,
.servicethreemark,
.servicetwomark {
  overflow: hidden;
  position: absolute;
  width: 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.servicetype {
  overflow: hidden;
  line-height: 150%;
  text-align: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
  height: 100%;
  left: -100%;
  top: -100%;
  background-color: #BFDDDD;
}

.servicetype span {
  width: 100%;
  display: block;
  position: absolute;
  line-height: 150%;
  font-size: 21px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.servicetype.trans {
  transition: all 0.5s;
  backface-visibility: hidden;
}

.serviceonemark {
  top: 0;
  left: 0;
}

.serviceonemark img {
  width: 100%;
}

.servicetwomark {
  top: 0;
  left: 51%;
}

.servicetwomark img {
  width: 100%;
}

.servicethreemark {
  top: 34%;
  left: 51%;
}

.servicethreemark img {
  width: 100%;
}

.servicefourmark {
  top: 51%;
  left: 0;
}

.servicefourmark img {
  width: 100%;
}

.servicefivemark {
  left: 51%;
  height: 32.4%;
}

.servicefivemark img {
  width: 100%;
}

.resume {
  min-width: 960px;
  max-width: 1230px;
  text-align: center;
  margin: 0 auto 50px;
}

.resume h2 {
  font-size: 28px;
  font-weight: normal;
  padding: 20px 0 24px;
}

.resume h2:last-of-type {
  padding: 20px 0 29px;
}

.resume .content {
  padding-bottom: 30px;
}

.resume .content img {
  width: 100%;
}

.resume h3 {
  font-size: 21px;
  font-weight: normal;
  line-height: 36px;
  margin-bottom: 30px;
}

.resume ul {
  min-width: 960px;
  max-width: 1230px;
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.resume ul li {
  padding: 20px 30px 30px;
  background: #333;
  margin-bottom: 20px;
  color: #fff;
  border-radius: 4px;
  width: 545px;
  text-align: left;
}

.resume ul li h4, .resume ul li h5, .resume ul li p {
  line-height: 1.5;
}

.resume ul li h4 {
  font-size: 32px;
}

.resume ul li h5 {
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 10px;
}

.resume ul li p {
  font-size: 14px;
  color: #999;
}

.aboutstype_four,
.aboutstype_one,
.aboutstype_three,
.aboutstype_two {
  width: 1230px;
  position: relative;
}

.aboutstype_four > img,
.aboutstype_one > img,
.aboutstype_three > img,
.aboutstype_two > img {
  float: left;
  margin: 0 1% 1% 0;
}

.aboutstype_four img:nth-child(1),
.aboutstype_one img:nth-child(1),
.aboutstype_three img:nth-child(1),
.aboutstype_two img:nth-child(1) {
  width: 100%;
}

.aboutstype_four img:nth-child(2),
.aboutstype_one img:nth-child(2),
.aboutstype_three img:nth-child(2),
.aboutstype_two img:nth-child(2) {
  width: 25%;
}

.aboutstype_four img:nth-child(3),
.aboutstype_one img:nth-child(3),
.aboutstype_three img:nth-child(3),
.aboutstype_two img:nth-child(3) {
  width: 48%;
  height: 101%;
}

.aboutstype_four img:nth-child(4),
.aboutstype_one img:nth-child(4),
.aboutstype_three img:nth-child(4),
.aboutstype_two img:nth-child(4) {
  width: 25%;
  margin: 0 0 1% 0;
}

.aboutstype_four span,
.aboutstype_one span,
.aboutstype_three span,
.aboutstype_two span {
  float: left;
}

.aboutstype_four > p,
.aboutstype_one > p,
.aboutstype_three > p,
.aboutstype_two > p {
  position: absolute;
  padding: 1px 3px;
  color: white;
  text-align: center;
  width: 230px;
}

.aboutstype_four > p .tippoint,
.aboutstype_one > p .tippoint,
.aboutstype_three > p .tippoint,
.aboutstype_two > p .tippoint {
  animation: shick 2s ease infinite;
}

.aboutstype_four > p img,
.aboutstype_one > p img,
.aboutstype_three > p img,
.aboutstype_two > p img {
  float: left;
}

.aboutstype_four > p .postext,
.aboutstype_one > p .postext,
.aboutstype_three > p .postext,
.aboutstype_two > p .postext {
  position: absolute;
  bottom: 1px;
  left: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
  padding: 5px 5px;
  width: auto;
  display: inline-block;
  padding-right: 10px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
}

.aboutstype_four > p #hermespostext,
.aboutstype_one > p #hermespostext,
.aboutstype_three > p #hermespostext,
.aboutstype_two > p #hermespostext {
  transform: rotate(180deg);
  border-top-right-radius: 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0px;
}

.aboutstype_four > p .corner1,
.aboutstype_one > p .corner1,
.aboutstype_three > p .corner1,
.aboutstype_two > p .corner1 {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 15px solid rgba(0, 0, 0, 0.6);
  border-bottom: 15px solid transparent;
  margin-top: -9px;
}

@keyframes shick {
  0% {
    transform: scale(0.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.posiAone {
  left: 43%;
  top: 39%;
}

.posiAtwo {
  left: 11%;
  top: 90%;
}

.posiAthree {
  left: 44%;
  top: 90%;
}

.posiAfour {
  left: 85%;
  top: 76%;
}

.posiBone {
  left: 62%;
  top: 39%;
}

.posiBtwo {
  left: 10%;
  top: 67%;
}

.posiBthree {
  left: 44%;
  top: 54%;
  transform: rotate(180deg);
}

.posiBfour {
  left: 86%;
  top: 76%;
}

.posiCone {
  left: 37%;
  top: 26%;
}

.posiCtwo {
  left: 7%;
  top: 84%;
}

.posiCthree {
  left: 41%;
  top: 65%;
}

.posiCfour {
  left: 82%;
  top: 66%;
}

.posiDtwo {
  left: 6%;
  top: 85%;
}

.posiDthree {
  left: 53%;
  top: 79%;
}

.posiDfour {
  left: 80%;
  top: 82%;
}

.TextTitle {
  margin: 0 auto;
  width: 120px;
  height: 160px;
  font-size: 32px;
}

.innertitle {
  position: relative;
}

.TextTitle img {
  width: 120px;
  position: absolute;
  top: 70px;
  left: 0;
}

.TitleDetail {
  position: absolute;
  top: 52px;
  left: 26px;
}

.show_icon {
  position: relative;
  height: 100px;
  width: 800px;
  margin: 30px auto 20px;
  display: flex;
  justify-content: space-around;
}

.show_icon div {
  cursor: pointer;
  z-index: 5555;
}

.show_icon p {
  text-align: center;
}

.selected {
  position: absolute;
  top: 8px;
  left: 62px;
  transform: scale(0.5);
}

.contrast_Bleft,
.contrast_Bright,
.contrast_left,
.contrast_right {
  position: absolute;
  width: 50%;
  height: 100px;
  display: inline-block;
  overflow: hidden;
}

.contrast_Bleft div,
.contrast_Bright div,
.contrast_left div,
.contrast_right div {
  float: left;
}

.contrast_Bleft img,
.contrast_Bright img,
.contrast_left img,
.contrast_right img {
  width: 100%;
  float: left;
}

.contrast_left {
  top: 0;
  left: 0;
}

.contrast_right {
  top: 0;
  left: 51%;
}

.contrast_Bleft {
  top: 343px;
  left: 0;
}

.contrast_Bright {
  top: 343px;
  left: 51%;
}

.contrastlogo {
  position: absolute;
  bottom: 0px;
  z-index: 15;
  left: 50%;
  transform: translateX(-50%) scale(0.6);
}

.conleft {
  right: 50%;
  position: absolute;
}

.conright {
  left: 50%;
  position: absolute;
}

.vfx {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  width: 600px;
  cursor: ew-resize;
}

.before-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.after-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: auto;
}

.joinus {
  height: 375px;
  margin: 50px auto 10px;
  text-align: center;
}

.joinword {
  font-size: 64px;
}

.joinnextword {
  font-size: 28px;
  letter-spacing: 1.75px;
  margin-bottom: 10px;
}

.email,
.joindetail {
  font-size: 21px;
  letter-spacing: 1.31px;
  padding-top: 8px;
}

.joindetail {
  padding: 17px;
}

.line64 {
  width: 64px;
  height: 2px;
  margin: 30px auto;
  border-top: 2px solid black;
}

.positiondetail {
  margin-top: 30px;
  height: 370px;
  text-align: center;
  border-top: 1px solid #cccccc;
}

.inpositiondetail {
  width: 740px;
  margin: 60px auto 0;
  display: flex;
  justify-content: space-around;
}

.baozhengdetail,
.baozhenglocation {
  font-size: 15px;
  width: 329px;
  height: 249px;
}

.baozhengdetail ul,
.baozhenglocation ul {
  height: 50px;
  padding: 17px 0;
  list-style: none;
}

.baozhengdetail ul li,
.baozhenglocation ul li {
  float: left;
}

.baozhengdetail ul li:last-child {
  margin-left: 20px;
  text-align: left;
}

.baomap {
  width: 329px;
  height: 249px;
}

.contactus {
  color: #000000;
  width: 140px;
  height: 40px;
  border: 1.3px solid #000000;
  display: block;
  margin: 40px auto 35px;
  text-align: center;
  line-height: 38px;
  font-size: 18px;
  letter-spacing: 2px;
  text-decoration: none;
}

.contactus:hover, .contactus:focus {
  text-decoration: none;
  list-style: none;
  color: #666666;
  border: 1.3px solid #666666;
}

.contactus:visited {
  text-decoration: none;
}

.contactus:active {
  color: #666666;
  border: 1.3px solid #666666;
}

#BeiJingICP {
  text-align: center;
  margin-top: -58px;
  font-size: 15px;
  color: #666666;
}

.fixed {
  position: fixed;
  right: 20px;
  bottom: 40px;
  width: 110px;
  text-align: right;
  cursor: pointer;
}

.fixed .code-detail {
  display: none;
}
