@import "icon";
.icon {
  background-image: url(#{$spritesheet-image});
  background-size: $spritesheet-width/2 auto;
  display: inline-block;
}
@mixin icon-sprite-width($sprite){
  width: nth($sprite, 5)/2;
}
@mixin icon-sprite-height($sprite) {
  height: nth($sprite, 6)/2;
}
@mixin icon-sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x/2  $sprite-offset-y/2;
}
@mixin icon-sprite($sprite) {
  @include icon-sprite-position($sprite);
  @include icon-sprite-width($sprite);
  @include icon-sprite-height($sprite);
}
@mixin icon-sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include icon-sprite($sprite);
    }
  }
}
@include icon-sprites($spritesheet-sprites);

// bower:scss
//@import "bower_components/bootstrap/scss/bootstrap.scss";
// endbower
/* Space out content a bit */
$auto: 0 auto;
@mixin backgroundsize {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

/*============================================*/
 body{margin:0;padding:0;background:#fff;font-size:14px;font-family:‘微软雅黑’,Arial,Helvetica,'STHeiti','Microsoft YaHei','sans-serif'}
 form,ul,li,p,h1,h2,h3,h4,h5,h6{margin:0;padding:0;}
 img{border:0;}
 ul,li{list-style-type:none;}
 a{color:#00007F;text-decoration:none;}
 a:hover{text-decoration:none;}
 /*===========================================*/

body {
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0;
  min-width: 960px;
  margin: 0 auto;
  font-family: ‘微软雅黑’;
  overflow-x: hidden;
  div {
    width: auto;
  }
}
@media only screen and (max-width: 960px) {
  body {
    overflow-x: auto;
    margin:0;
  }
}
//new

.topbar {
  background-color: #BFDDDD;
  height: 64px;
}

.banner,
.container_in,
.topbar,
footer,
footer img {
  min-width: 960px;
  margin: $auto;
  width: 100%;
  max-width: 1280px;

  .slick-next,
  .slick-prev {
    z-index: 5555;
    border: none;
    outline: none;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 74px;
    height: 74px;
    -webkit-transform: translate(0, -50%) scale(0.5);
    -ms-transform: translate(0, -50%) scale(0.5);
    transform: translate(0, -50%) scale(0.5);
    cursor: pointer;
    color: transparent;
    background: transparent;
    background-repeat: no-repeat;
    background-image:url("../images/other/css_sprites.png");
  }
  .slick-next {
    right: 25px;
    background-position: -10px -104px;
  }
  .slick-prev {
    background-position: -10px -10px;
    left: 25px;
  }

  .slick-dots {
    position: absolute;
    bottom: 6px;
    display: block;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
  }
}

.abouts,
.charges,
.contrast,
.positiondetail,
.service,
.symptom {
  margin: 0 auto;
  min-width: 896px;
  max-width: 1216px;
  width: 100%;
}

.abouts {
  min-width: 960px;
  max-width: 1230px;
  width: 100%;
}

.logoimg {
  float: left;
  margin: 14px 24px;
}

.navbar {
  float: right;
  width: 493px;
  height: 35px;
  padding-top: 20px;
}
.nav {
  display: flex;
}
.nav a {
  display: block;
  color: #000000;
  font-size: 17px;
  list-style: none;
  width: 40px;
  margin-right: 52px;
  text-decoration: none;
}

.nav a:hover {
  color: inherit;
  list-style: none;
}

.logoimg {
  width: 103px;
  height: auto;
}

.banner {
  height: auto;
  .bannertype_one,
  .bannertype_three,
  .bannertype_two {
    height: auto;

    @include backgroundsize;
    img{
      width: 100%;
    }
  }
}

.symptom {
  height: auto;

  button{
    outline: none;
  }
  .symptomtype_four,
  .symptomtype_one,
  .symptomtype_three,
  .symptomtype_two {
    height: auto;

    @include backgroundsize;
    img{
      height: auto;
      width: 100%;
    }
  }
}

.contrast {
  height: 676px;
  .contrasttype_four,
  .contrasttype_one,
  .contrasttype_three,
  .contrasttype_two {
    position: relative;
    max-width: 1216px;
    min-width: 960px;
    width: 100%;
    height: 676px;
  }
}

.charges {
  height: auto;

  button{
    outline: none;
  }

  .chargestype_four,
  .chargestype_one,
  .chargestype_three,
  .chargestype_two {
    height: auto;

    @include backgroundsize;
    img {
      height: auto;
      width: 100%;
    }
  }
}

.service {
  height: 1030px;
  position: relative;
}

.servicefivemark,
.servicefourmark,
.serviceonemark,
.servicethreemark,
.servicetwomark {
  overflow: hidden;
  position: absolute;
  width: 50%;
  @include backgroundsize;
}

.servicetype {
  overflow: hidden;
  line-height: 150%;
  text-align: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
  height: 100%;
  left: -100%;
  top: -100%;
  background-color: #BFDDDD;

  span {
    width: 100%;
    display: block;
    position: absolute;
    line-height: 150%;
    font-size: 21px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.servicetype.trans {
  transition: all 0.5s;
  backface-visibility: hidden;
}

.serviceonemark {
  top: 0;
  left: 0;
  img {
    width: 100%;
  }
}

.servicetwomark {
  top: 0;
  left: 51%;
  img {
    width: 100%;
  }
}

.servicethreemark {
  top: 34%;
  left: 51%;
  img {
    width: 100%;
  }
}

.servicefourmark {
  top: 51%;
  left: 0;
  img {
    width: 100%;
  }
}

.servicefivemark {
  left: 51%;
// top: 68%;
height: 32.4%;
  img {
    // height: 336px;
    width: 100%;
  }
}

.resume{
  min-width: 960px;
  max-width: 1230px;
  text-align: center;
  margin: 0 auto 50px;
  h2{font-size: 28px;font-weight: normal;padding: 20px 0 24px}
  h2:last-of-type{
    padding: 20px 0 29px
  }
  .content{
    padding-bottom: 30px;
    img{
      width: 100%;
    }
  }
  h3{
    font-size: 21px;
    font-weight: normal;
    line-height: 36px;
    margin-bottom: 30px;
  }

  ul{
    min-width: 960px;
    max-width: 1230px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li{
      padding: 20px 30px 30px;background: #333;
      margin-bottom: 20px;
      color: #fff;border-radius: 4px;
      width: 545px;
      text-align: left;
      h4,h5,p{line-height: 1.5}
      h4{
        font-size: 32px;
      }
      h5{
        font-size: 20px;
        font-weight: normal;
        margin-bottom: 10px;
      }
      p{
        font-size: 14px;color: #999;
      }
    }
  }


}


.aboutstype_four,
.aboutstype_one,
.aboutstype_three,
.aboutstype_two {
  width: 1230px;
  position: relative;

  > img {
    float: left;
    margin: 0 1% 1% 0;
  }
  img:nth-child(1){
    width: 100%;
  }
  img:nth-child(2){
    width: 25%;
  }
  img:nth-child(3){
    width: 48%;
    height: 101%;
  }
  img:nth-child(4){
    width: 25%;
    margin:  0 0 1% 0;
  }
  span {
    float: left;
  }

  > p {
    position: absolute;
    padding: 1px 3px;
    color: white;
    text-align: center;
    width: 230px;
    .tippoint {
    	animation: shick 2s ease infinite;
    }

    img {
    	float: left;
    }

    .postext {
      position: absolute;
      bottom: 1px;
      left: 30px;
      height: 30px;
      background-color: rgba(0, 0, 0, 0.6);
      font-size: 15px;
      padding: 5px 5px;
      width: auto;
      display: inline-block;
      padding-right: 10px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      box-sizing: border-box;
    }
    #hermespostext{
      transform: rotate(180deg);
      border-top-right-radius: 0px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 0px;
    }
    .corner1 {
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-right: 15px solid rgba(0, 0, 0, 0.6);;
      border-bottom: 15px solid transparent;
      margin-top: -9px;
		}
	}
}
@keyframes shick {
  0% {
    transform: scale(0.2);
    opacity: 0.8;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.posiAone {
  left: 43%;
  top: 39%;
}

.posiAtwo {
  left: 11%;
  top: 90%;
}

.posiAthree {
  left: 44%;
  top: 90%;
}

.posiAfour {
  left: 85%;
  top: 76%;
}

.posiBone {
  left: 62%;
  top: 39%;
}

.posiBtwo {
  left: 10%;
  top: 67%;
}

.posiBthree {
  left: 44%;
  top: 54%;
  transform: rotate(180deg);
}

.posiBfour {
  left: 86%;
  top: 76%;
}

.posiCone {
  left: 37%;
  top: 26%;
}

.posiCtwo {
  left: 7%;
  top: 84%;
}

.posiCthree {
  left: 41%;
  top: 65%;
}

.posiCfour {
  left: 82%;
  top: 66%;
}

.posiDtwo {
  left: 6%;
  top: 85%;
}

.posiDthree {
  left: 53%;
  top: 79%;
}

.posiDfour {
  left: 80%;
  top: 82%;
}

.TextTitle {
  margin: 0 auto;
  width: 120px;
  height: 160px;
  font-size: 32px;
}

.innertitle {
  position: relative;
}

.TextTitle img {
  width: 120px;
  position: absolute;
  top: 70px;
  left: 0;
}

.TitleDetail {
  position: absolute;
  top: 52px;
  left: 26px;
}

.show_icon {
  position: relative;
  div {
    cursor: pointer;
    z-index: 5555;
  }
  height: 100px;
  width: 800px;
  margin: 30px auto 20px;
  display: flex;
  justify-content: space-around;

  p {
    text-align: center;
    // margin-top: -20px;
  }
}

.choseicon {
  // span {
  //   transform: scale(0.5);
  // }
}

.selected {
  position: absolute;
  top: 8px;
  left: 62px;
  transform: scale(0.5);
}

.contrast_Bleft,
.contrast_Bright,
.contrast_left,
.contrast_right {
  position: absolute;
  width: 50%;
  height: 100px;
  display: inline-block;
  overflow: hidden;

  div {
    float: left;
  }

  img {
    width: 100%;
    float: left;
  }
}

.contrast_left {
  top: 0;
  left: 0;
}

.contrast_right {
  top: 0;
  left: 51%;
}

.contrast_Bleft {
  top: 343px;
  left: 0;
}

.contrast_Bright {
  top: 343px;
  left: 51%;
}

.contrastlogo {
  position: absolute;
  bottom: 0px;
  z-index: 15;
  left: 50%;
  transform: translateX(-50%) scale(0.6);
}

.conleft {
  right: 50%;
  position: absolute;
}

.conright {
  left: 50%;
  position: absolute;
}

.vfx {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  width: 600px;
  cursor: ew-resize;
}

.before-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.after-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width:100%;
  height: auto;
}

.joinus {
  height: 375px;
  margin: 50px auto 10px;
  text-align: center;
}

.joinword {
  font-size: 64px;
}

.joinnextword {
  font-size: 28px;
  letter-spacing: 1.75px;
  margin-bottom: 10px;
}

.email,
.joindetail {
  font-size: 21px;
  letter-spacing: 1.31px;
  padding-top: 8px;
}
.joindetail{
  padding: 17px;
}
.line64 {
  width: 64px;
  height: 2px;
  margin: 30px auto;
  border-top: 2px solid black;
}

.positiondetail {
  margin-top: 30px;
  height: 370px;
  text-align: center;
  border-top: 1px solid #cccccc;
}

.inpositiondetail {
  width: 740px;
  margin: 60px auto 0;
  display: flex;
  justify-content: space-around;
}

.baozhengdetail,
.baozhenglocation {
  font-size: 15px;
  width: 329px;
  height: 249px;

  ul {
    height:50px;
    padding: 17px 0;
    list-style: none;
  }

  ul li {
    float: left;
  }
}

.baozhengdetail ul li:last-child {
  margin-left: 20px;
  text-align: left;
}

.baomap {
  width: 329px;
  height: 249px;
}
.contactus{
  color: #000000;
  width: 140px;
  height: 40px;
  border: 1.3px solid #000000;
  display: block;
  margin: 40px auto 35px;
  text-align: center;
  line-height: 38px;
  font-size: 18px;
  letter-spacing: 2px;
  text-decoration: none;
  &:hover,&:focus{
    text-decoration:none;
    list-style: none;
    color: #666666;
    border: 1.3px solid #666666;
  }
  &:visited{
    text-decoration:none;
  }
  &:active{
    color: #666666;
    border: 1.3px solid #666666;
  }
}
#BeiJingICP{
  text-align: center;
  margin-top: -58px;
  font-size: 15px;
  color: #666666;
}

.fixed{
  position: fixed;
  right: 20px;bottom:40px;
  width: 110px;
  text-align: right;
  cursor: pointer;

  .code-detail{display: none}
}
